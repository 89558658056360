<template>
  <div class="hardness">
    <div class="view">
      <div class="block" :style="hardnessFun(hardness)"></div>
    </div>
    <div class="txtView">
      <span> {{ $t("localization.components.hardness.soft") }}</span>
      <span>{{ $t("localization.components.hardness.hard") }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    percentage: {
      type: Number,
      default() {
        return 0;
      },
    },
  },
  watch: {
    percentage: {
      handler(newName, oldName) {
        this.hardness = newName;
      },
      immediate: true,
    },
  },
  data() {
    return {
      hardness: 0,
    };
  },
  methods: {
    hardnessFun(num) {
      if (num == 100) {
        return `left:calc(${num}% - 18px)`;
      } else {
        return `left:${num}%`;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.hardness {
  .view {
    height: 10px;
    width: 100%;
    margin: 10px 0;
    background-color: #244e88; /* 不支持线性的时候显示 */
    background-image: linear-gradient(
      to right,
      #deecf7,
      #91b6e0,
      #3664a6,
      #18314f,
      #020107
    );
    position: relative;
    .block {
      width: 18px;
      height: 24px;
      border: solid 3px #d50d17;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      transition: all 0.5s ease-in-out;
      -webkit-transition: all 0.5s ease-in-out;
      -moz-transition: all 0.5s ease-in-out;
      -ms-transition: all 0.5s ease-in-out;
      -o-transition: all 0.5s ease-in-out;
      box-sizing: border-box;
    }
  }
  .txtView {
    display: flex;
    justify-content: space-between;
    span {
      font-size: 16px;
      color: #333;
    }
  }
}
</style>