<template>
  <div class="content">
    <div class="main">
      <section class="productSwiper">
        <div class="productSwiperView">
          <div class="productSwiperBox" v-if="product_swiper.length != 0">
            <div class="leftView">
              <div class="swiperTopView">
                <swiper
                  :options="swiperOptionOne"
                  class="gallery-top"
                  ref="swiperTopRef"
                >
                  <swiper-slide
                    class="slide-item"
                    v-for="item in product_swiper"
                    :key="item"
                  >
                    <img :src="item" alt="" />
                  </swiper-slide>
                </swiper>
                <div class="swiper-button-prev swiper_btn" slot="button-prev">
                  <i class="el-icon-arrow-left"></i>
                </div>
                <div class="swiper-button-next swiper_btn" slot="button-next">
                  <i class="el-icon-arrow-right"></i>
                </div>
              </div>
              <!-- swiper2 Thumbs -->
              <div class="galleryThumbsView">
                <div class="swiper-pagination" slot="pagination"></div>
              </div>
            </div>
            <div class="rightView">
              <h4 class="NotoSansTCBold">{{ e_title }}</h4>
              <h4 class="NotoSansTCBold">{{ title }}</h4>
              <!-- 筛选 start -->
              <div class="radioView NotoSansTCLight">
                <div
                  class="radioItem"
                  :class="radioOneIndex == index ? 'active' : ''"
                  v-for="(item, index) in product_switching"
                  :key="index"
                  @click="radioFun(index, 'radioOneIndex')"
                >
                  {{ item.spec_name }}
                </div>
              </div>
              <div class="radioView NotoSansTCLight">
                <div
                  class="radioItem"
                  :class="radioTwoIndex == index ? 'active' : ''"
                  v-for="(item, index) in product_switching[radioOneIndex]
                    .second_spec"
                  :key="index"
                  @click="radioFun(index, 'radioTwoIndex')"
                >
                  {{ item.spec_name }}
                </div>
              </div>
              <!-- 筛选 end -->
              <div class="itemView">
                <h5>Application 應用</h5>
                <p
                  class="NotoSansTCLight"
                  v-if="
                    product_switching[radioOneIndex].second_spec.length != 0 &&
                    product_switching[radioOneIndex].second_spec[radioTwoIndex]
                      .application_standard != null
                  "
                >
                  {{
                    product_switching[radioOneIndex].second_spec[radioTwoIndex]
                      .application_standard.application
                  }}
                </p>
              </div>
              <div class="itemView">
                <h5>Standard 標準</h5>
                <p
                  class="NotoSansTCLight"
                  v-if="
                    product_switching[radioOneIndex].second_spec.length != 0 &&
                    product_switching[radioOneIndex].second_spec[radioTwoIndex]
                      .application_standard != null
                  "
                >
                  {{
                    product_switching[radioOneIndex].second_spec[radioTwoIndex]
                      .application_standard.standard
                  }}
                </p>
              </div>
              <div class="imgView">
                <img
                  v-if="
                    product_switching[radioOneIndex].second_spec.length != 0 &&
                    product_switching[radioOneIndex].second_spec[radioTwoIndex]
                      .application_standard != null
                  "
                  :src="
                    product_switching[radioOneIndex].second_spec[radioTwoIndex]
                      .application_standard.spec_attachment_url
                  "
                  alt=""
                />
              </div>
              <template
                v-if="
                  product_switching[radioOneIndex].second_spec.length != 0 &&
                  product_switching[radioOneIndex].second_spec[radioTwoIndex]
                    .application_standard != null
                "
              >
                <hardness
                  v-if="
                    product_switching[radioOneIndex].second_spec[radioTwoIndex]
                      .application_standard.hard_soft != 0
                  "
                  class="hardness"
                  :percentage="
                    product_switching[radioOneIndex].second_spec[radioTwoIndex]
                      .application_standard.hard_soft
                  "
                />
              </template>
            </div>
          </div>
          <div class="partsView">
            <div class="title NotoSansTCBold">配件</div>
            <div class="partsBox">
              <router-link
                :to="`/parts_details?id=${item.id}&status=1`"
                class="partsItem"
                v-for="(item, index) in product_parts"
                :key="index"
              >
                <img :src="item.attachment_url" alt="" />
              </router-link>
            </div>
          </div>
        </div>
      </section>
      <section class="partsSwiper" v-show="partsList.length != 0">
        <div class="partsSwiperView">
          <!-- swiper1 -->
          <div class="swiperOne">
            <swiper
              class="swiper gallery-top"
              :options="swiperOptionTop"
              ref="swiperTop"
            >
              <swiper-slide
                class="slide-item"
                v-for="(item, index) in partsList"
                :key="index"
              >
                <img :src="item.intro_pic_json" alt="" />
              </swiper-slide>
            </swiper>
          </div>
          <!-- swiper2 Thumbs -->
          <div class="swiperTwo">
            <swiper
              class="swiper gallery-thumbs"
              :options="swiperOptionThumbs"
              ref="swiperThumbs"
            >
              <swiper-slide
                class="slide-item"
                v-for="(item, index) in partsList"
                :key="index"
              >
                <h4 class="NotoSansTCBold">{{ item.intro_title }}</h4>
                <p>{{ item.intro_content }}</p>
              </swiper-slide>
            </swiper>
            <div class="operation">
              <div class="swiper-button-prev swiper_btn" slot="button-prev">
                <i class="el-icon-arrow-left"></i>
              </div>
              <div class="swiper-pagination" slot="pagination"></div>
              <div class="swiper-button-next swiper_btn" slot="button-next">
                <i class="el-icon-arrow-right"></i>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div class="autoHardnessView">
        <auto-hardness :data="detailed" />
      </div>
      <!--  rpa入口 zd 20220811  -->
      <div class="rpa-entrance-img" @click="toRpa" v-show="product_id === 29">
        <img :src="rpaEntranceProductBanner.pic" alt="" />
      </div>
      <div class="autoHardnessView">
        <div class="specificationsTestView">
          <div class="choiceView">
            <div class="title NotoSansTCBold">
              <h3>{{ e_title }}</h3>
              <h3>{{ title }}</h3>
              <h4>測試規格</h4>
            </div>
            <div class="radioView" v-if="specsList.length != 0">
              <div
                class="radioItem"
                :class="index == specsValue ? 'active' : ''"
                v-for="(item, index) in specsList"
                :key="index"
                @click="specsValue = index"
              >
                {{ item.spec_name }}
              </div>
            </div>
          </div>
        </div>
        <div class="specificationsMain" v-if="specsList.length != 0">
          <swiper
            :options="swiperSpecifications"
            class="swiperSpecifications"
            ref="swiper"
          >
            <template v-if="specsList">
              <swiper-slide
                class="slide-item"
                v-for="(item, index) in specsList[specsValue].second_digi_spec"
                :key="index"
              >
                <specifications :item="item" />
              </swiper-slide>
            </template>
          </swiper>
          <div class="swiper-button-prev swiper_btn" slot="button-prev">
            <i class="el-icon-arrow-left"></i>
          </div>
          <div class="swiper-button-next swiper_btn" slot="button-next">
            <i class="el-icon-arrow-right"></i>
          </div>
        </div>

        <div class="line"></div>
        <div class="linkWrap">
          <div class="item" v-for="(item, index) in linkList" :key="index">
            <router-link
              :to="`/knowledge_base_detailed?id=${item.id}&pid=${item.f_cate_id}&twoId=${item.cate_id}`"
              class="link"
            >
              <div class="imgView">
                <img :src="item.pic" alt="" />
              </div>
              <div class="txtView">
                <span>{{ item.create_time }}</span>
                <p>{{ item.title }}</p>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
import hardness from "@/components/hardness";
import autoHardness from "@/components/autoHardness";
import specifications from "@/components/specifications";
import store from "@/store";
export default {
  name: "ProductDetails",
  components: {
    swiper,
    swiperSlide,
    hardness,
    autoHardness,
    specifications,
  },
  data() {
    return {
      stores: store,
      id: this.$route.query.id,
      title: "",
      e_title: "",

      swiperOptionOne: {
        navigation: {
          nextEl: ".productSwiper .swiper-button-next",
          prevEl: ".productSwiper .swiper-button-prev",
        },
        pagination: {
          el: ".productSwiper .swiper-pagination",
          clickable: true,
          renderBullet: (index, className) => {
            return `<div class="paginationItem ${className}">
              <img src="${this.product_swiper[index]}" />
            </div>`;
          },
        },
      },
      product_swiper: [],
      radioOneIndex: 0,
      radioTwoIndex: 0,
      product_parts: [],
      detailed: {},

      swiperSpecifications: {
        slidesPerView: 2,
        spaceBetween: 58,
        slidesPerGroup: 2,
        loopFillGroupWithBlank: true,
        navigation: {
          nextEl: ".specificationsMain .swiper-button-next",
          prevEl: ".specificationsMain .swiper-button-prev",
        },
        breakpoints: {
          768: {
            slidesPerView: 1,
            spaceBetween: 0,
            slidesPerGroup: 1,
          },
        },
      },
      swiperOptionTop: {
        spaceBetween: 10,
      },
      swiperOptionThumbs: {
        watchSlidesVisibility: true,
        watchSlidesProgress: true,
        navigation: {
          nextEl: ".operation .swiper-button-next",
          prevEl: ".operation .swiper-button-prev",
        },
        pagination: {
          el: ".operation .swiper-pagination",
          clickable: true,
        },
      },
      partsList: [],

      specsList: [],
      specsValue: 0,
      list: [],
      linkList: [],

      product_switching: [],
      rpaEntranceProductBanner: '', //rpa产品页入口图
      product_id: 0
    };
  },
  computed: {
    swiper() {
      return this.$refs.swiper.swiper;
    },
  },
  mounted() {
    this.getData();
    // this.linkListFun();
    this.getEntrance();
    this.$nextTick(() => {
      const swiperTop = this.$refs.swiperTop.swiper;
      const swiperThumbs = this.$refs.swiperThumbs.swiper;
      swiperTop.controller.control = swiperThumbs;
      swiperThumbs.controller.control = swiperTop;
    });
  },
  methods: {
    /**获取数据 */
    getData() {
      this.$http.productDetails({ id: this.id }).then((res) => {
        if (res.code == 200) {
          this.title = res.data.name;
          this.e_title = res.data.e_title;
          this.product_parts = res.data.relevant; //配件
          this.product_switching = res.data.product_switching;
          this.partsList = res.data.intro_content; //产品介绍
          this.specsList = res.data.product_digitest_switching;
          this.product_swiper = res.data.swiper_json;
          this.linkList = res.data.relevant_knowledge;
          this.detailed = {
            detailed_content: res.data.detailed_content,
            detailed_pic_json: res.data.detailed_pic_json,
            detailed_title: res.data.detailed_title,
          };
          this.product_id = res.data.id;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    /**
     * 首页接口 用来请求最下面的链接跳转
     */
    // linkListFun() {
    //   this.$http.home().then((res) => {
    //     if (res.code == 200) {
    //       this.linkList = res.data.index_knowledge;
    //     } else {
    //       this.$message.error(res.msg);
    //     }
    //   });
    // },
    /**
     * 筛选
     */
    radioFun(i, k) {
      if (k == "radioOneIndex") {
        this.radioTwoIndex = 0;
      }
      this[k] = i;
    },
    //rpa首页产品页入口图 zd 20220811
    getEntrance(){
      this.$http.getRpaEntranceBanner().then((res) => {
        if (res.code == 200) {
          this.rpaEntranceProductBanner = res.data.product;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    toRpa(){
      this.$router.push({
        path: "/rpa-ultra"
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/style/productDetails.scss";
</style>
