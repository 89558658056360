<!--
 * @Author: 苏航
 * @Date: 2021-05-25 08:35:14
 * @LastEditors: 苏航
 * @LastEditTime: 2021-07-27 08:27:37
 * @version: V.x
 * @Descripttion: 说明...
-->
<template>
  <div class="autoHardness">
    <h3>{{ data.detailed_title }}</h3>
    <p>
      {{ data.detailed_content }}
    </p>
    <div class="iconView">
      <div class="item" v-for="item in data.detailed_pic_json" :key="item">
        <img :src="item" alt="" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    //数据
    data: {
      type: Object,
      default() {
        return {};
      },
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/style/variables.scss";
.autoHardness {
  width: 100%;
  h3 {
    text-align: center;
    font-size: 32px;
    color: #333;
    font-weight: normal;
    margin-bottom: 15px;
    @include respond-to(sm) {
      font-size: 24px;
      margin-bottom: 10px;
    }
  }
  p {
    width: 868px;
    color: #666;
    font-size: 16px;
    text-align: center;
    margin: 0 auto;
    @include respond-to(md) {
      width: 100%;
    }
    @include respond-to(sm) {
      font-size: 14px;
    }
  }
  .iconView {
    display: flex;
    justify-content: space-between;
    margin-top: 60px;
    flex-wrap: wrap;
    @include respond-to(sm) {
      margin-top: 20px;
    }
    .item {
      display: flex;
      flex-direction: column;
      align-items: center;
      img {
        margin-bottom: 20px;
        display: block;
      }
      span {
        font-size: 16px;
        color: #1a1a1a;
      }
    }
  }
}
</style>