<template>
  <div class="specifications">
    <div class="imgView">
      <img class="bg" :src="item.imgBgurl" alt="" />
      <img
        v-show="show_sort == item_img.sort"
        v-for="(item_img, index_img) in item.digi_test"
        :key="index_img"
        class="cover"
        :src="item_img.t_s_attachment_url"
        alt=""
      />
    </div>
    <div class="hoverView">
      <div class="title NotoSansTCBold">{{ item.spec_name }}</div>
      <div
        class="item"
        :class="show_sort == item_img.sort ? 'active' : ''"
        v-for="(item_img, index_img) in item.digi_test"
        :key="index_img"
        @mouseover="mouseoverItem(item_img)"
        @mouseout="mouseoutItem(item_img)"
      >
        <div class="leftView">
          <div class="sort">{{ item_img.sort }}</div>
          <div class="name NotoSansTCMedium">{{ item_img.spec_title }}</div>
        </div>
        <div class="value">{{ item_img.spec_value }}</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    item: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  data() {
    return {
      show_sort: "",
    };
  },
  mounted() {},
  methods: {
    /**
     * 接触
     */
    mouseoverItem(item) {
      this.show_sort = item.sort;
    },
    /**
     * 松开
     */
    mouseoutItem(item) {
      this.show_sort = "";
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/style/variables.scss";
.specifications {
  .imgView {
    position: relative;
    margin-bottom: 80px;
    @include respond-to(sm) {
      margin-bottom: 20px;
    }
    img {
      width: 100%;
      display: block;
      &.cover {
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }
  .hoverView {
    .title {
      font-size: 24px;
      color: #010010;
      font-weight: bold;
      padding-bottom: 10px;
      @include respond-to(sm) {
        font-size: 20px;
      }
    }
    .item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 70px;
      border-top: 1px solid #e6e6e6;
      box-sizing: border-box;
      cursor: pointer;
      @include respond-to(sm) {
        height: 50px;
      }
      .leftView {
        display: flex;
        align-items: center;
        .sort {
          width: 22px;
          height: 22px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          line-height: 1;
          font-size: 14px;
          color: #fff;
          background-color: #202022;
          margin-right: 26px;
          @include respond-to(sm) {
            margin-right: 10px;
            width: 20px;
            height: 20px;
            font-size: 12px;
          }
        }
        .name {
          font-size: 16px;
          color: #1a1a1a;
          @include respond-to(sm) {
            font-size: 14px;
          }
        }
      }
      .value {
        font-size: 16px;
        color: #1a1a1a;
        @include respond-to(sm) {
          font-size: 12px;
        }
      }
      &.active {
        .leftView {
          .sort {
            background-color: #0075c1;
            color: #fff;
          }
          .name {
            color: #0075c1;
          }
        }
      }
    }
  }
}
</style>